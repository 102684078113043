var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { padding: "0 20px" } },
    [
      _c(
        "el-row",
        { staticStyle: { margin: "30px 0" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("category-picker", {
                attrs: {
                  type: 0,
                  expanded: true,
                  accordion: true,
                  searchable: true
                },
                on: { check: _vm.updateCategories }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "text-align": "center" }, attrs: { span: 12 } },
            [
              _c("h5", [_vm._v("Filtrér resultatene")]),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.onlyApprovedSellers,
                    callback: function($$v) {
                      _vm.onlyApprovedSellers = $$v
                    },
                    expression: "onlyApprovedSellers"
                  }
                },
                [_vm._v("\n        Lærer må være godkjent\n      ")]
              ),
              _c(
                "el-row",
                { staticStyle: { margin: "20px 0" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "#009BFF",
                        large: "",
                        dark: "",
                        disabled: !_vm.categories.length,
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("\n          Søk\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("h2", [_vm._v("Fant " + _vm._s(_vm.sellers.length) + " lærere")]),
          _c(
            "el-row",
            [
              _c("seller-list", {
                attrs: { list: _vm.sellers, autoload: true }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }