<template>
  <el-row style="padding: 0 20px">
    <el-row style="margin: 30px 0">
      <el-col :span="12">
        <category-picker
          :type="0"
          :expanded="true"
          :accordion="true"
          :searchable="true"
          @check="updateCategories"
        />
      </el-col>
      <el-col :span="12" style="text-align: center">
        <h5>Filtrér resultatene</h5>
        <el-checkbox v-model="onlyApprovedSellers">
          Lærer må være godkjent
        </el-checkbox>
        <el-row style="margin: 20px 0">
          <v-btn
            color="#009BFF"
            large
            dark
            :disabled="!categories.length"
            :loading="loading"
            @click="search()"
          >
            Søk
          </v-btn>
        </el-row>
      </el-col>
    </el-row>

    <el-row v-loading="loading">
      <h2>Fant {{ sellers.length }} lærere</h2>
      <el-row>
        <seller-list :list="sellers" :autoload="true" />
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import CategoryPicker from '../Categorypicker';

export default {
  components: { CategoryPicker },
  data() {
    return {
      categories: [],
      loading: false,
      onlyApprovedSellers: true,
      sellers: [],
    };
  },
  methods: {
    async search() {
      this.loading = true;
      this.sellers = [];

      const sellerRequest = await this.$axios.post('/admin/sellers/categories', this.categories);

      this.sellers = sellerRequest.data.filter(p => (this.onlyApprovedSellers === false ||
        (this.onlyApprovedSellers === true &&
          (p.profile.certificateApproved && p.profile.identificationApproved && p.profile.policeRecordApproved))));

      this.loading = false;
    },
    updateCategories(checkedCategories) {
      this.categories = checkedCategories;
    },
  },
};
</script>
