var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { attrs: { color: "primary", "slider-color": "orange", grow: "" } },
    [
      _c("v-tab", [_vm._v("\n    Søk\n  ")]),
      _c("v-tab-item", [_c("search")], 1),
      _c("v-tab", [_vm._v("\n    Kø\n  ")]),
      _c(
        "v-tab-item",
        [_c("seller-list", { key: "queue", attrs: { path: "queue" } })],
        1
      ),
      _c("v-tab", [_vm._v("\n    Køforespørsler\n  ")]),
      _c(
        "v-tab-item",
        [
          _c("seller-list", {
            key: "queueRequests",
            attrs: { path: "queue/requests" }
          })
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Lærere som venter godkjenning\n  ")]),
      _c(
        "v-tab-item",
        [
          _c("seller-list", {
            key: "awaitingApproval",
            attrs: { path: "awaitingApproval" }
          })
        ],
        1
      ),
      _c("v-tab", [_vm._v("\n    Nylig godkjente lærere\n  ")]),
      _c(
        "v-tab-item",
        [_c("seller-list", { key: "approved", attrs: { path: "approved" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }