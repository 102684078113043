<template>
  <v-tabs color="primary" slider-color="orange" grow>
    <v-tab>
      Søk
    </v-tab>
    <v-tab-item>
      <search />
    </v-tab-item>

    <v-tab>
      Kø
    </v-tab>
    <v-tab-item>
      <seller-list
        :key="'queue'"
        :path="'queue'"
      />
    </v-tab-item>

    <v-tab>
      Køforespørsler
    </v-tab>
    <v-tab-item>
      <seller-list
        :key="'queueRequests'"
        :path="'queue/requests'"
      />
    </v-tab-item>

    <v-tab>
      Lærere som venter godkjenning
    </v-tab>
    <v-tab-item>
      <seller-list
        :key="'awaitingApproval'"
        :path="'awaitingApproval'"
      />
    </v-tab-item>

    <v-tab>
      Nylig godkjente lærere
    </v-tab>
    <v-tab-item>
      <seller-list
        :key="'approved'"
        :path="'approved'"
      />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import Search from '../components/sellers/Search'

export default {
  components: {
    Search
  }
}
</script>
